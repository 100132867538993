<template>
  <div class="todo">
    <div class="content">
      <ModTit>
        <template v-slot:left>
          <h3>
            {{
              module.menuJsonObj
                ? module.menuJsonObj[langNamesEnum[languageActive]]
                : module.title
            }}（{{ todoDataTotal }}{{ $t("Home.pcs")
            }}<!-- 个 -->）
            
          </h3>
        </template>
        <template v-slot:right>
          <router-link style="color: #666;" class="more" :to="{ path: '/mine' }">
            {{ $t('Home.SeeMore') }}
            <RightOutlined class="icon" />
          </router-link>
        </template>
      </ModTit>
      <div class="card">
        <div
          class="item"
          v-for="(todo, i) in todoList[currentIndex]"
          :key="i"
          @click="jump(todo)"
        >
          <div class="top">
            <div class="type">
              {{ taskType[todo.taskType].name }}
            </div>
            <div class="status green" v-if="now >= todo.startTime">
              {{ $t("Home.inProgress") }}
            </div>
            <div class="status orange" v-else>
              {{ $t("Home.beginInAMinute") }}
            </div>
          </div>
          <div class="bottom">
            <div class="cover">
              <img
                :src="
                  require(`@/assets/image/mine/tasks/${
                    taskType[todo.taskType].code
                  }.png`)
                "
                alt=""
              />
            </div>
            <div class="info">
              <div class="title">
                {{ todo.taskName  }}
              </div>
              <div class="time">
                {{ dateFormat(todo.startTime) }}-{{ dateFormat(todo.endTime) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { dateFormat } from "@/utils/tools";
import { getDetailType } from "@/utils/business";
import { projectDetail } from "@/api/project";
import ModTit from "@/components/layout/ModTit.vue";
export default {
  name: "todo",
  components: {
    ModTit,
  },
  props: {
    module: {
      type: Object,
      default: () => {
        return {};
      },
    },
    langNamesEnum: {
      type: Object,
      default: () => {
        return {};
      },
    },
    languageActive: {
      type: String,
      default: "",
    },
    dataSource: {
      type: Array,
      default: () => {
        return [];
      },
    },
    todoDataTotal: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  setup(props) {
    const { t: $t } = useI18n();
    let now = Date.parse(new Date()) / 1000;
    const taskType = {
      1: { name: $t("MN_TrainingProject_MHome"), code: "project" }, // 项目培训
      11: { name: $t("MN_TrainingProject_MHome"), code: "project" }, // 项目培训
      14: { name: $t("MN_TrainingProject_MHome"), code: "project" }, // 项目培训
      6: { name: $t("Pub_LP"), code: "map" }, // 学习地图
      9: { name: $t("cm_newstaff"), code: "newstaff" }, // 新人培训
      5: { name: $t("cm_subject"), code: "subject" }, // 课程专题
      10: { name: $t("Sys_Exam"), code: "exam" }, // 考试
      2: { name: $t("daily_exam"), code: "cyclic" }, // 每日一练
      12: { name: $t("cm_activity"), code: "activity" }, // 活动
      16: { name: $t("Pub_Tab_CT"), code: "face" }, // 面授
      20: { name: $t("CM_Live"), code: "live" }, // 直播
    };
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    // 数组 一维转二维
    const arrTrans = (arr, num) => {
      const newArr = [];
      const total = Math.ceil(arr.length / num);
      for (let i = 0; i < total; i++) {
        const temp = arr.slice(i * num, (i + 1) * num);
        newArr.push(temp);
      }
      return newArr;
    };
    const todoList = ref(props.dataSource);
    const currentIndex = ref(0);
    const initData = () => {
      todoList.value = arrTrans(props.dataSource, 3);
      const nowS = Date.parse(new Date()) / 1000;
      todoList.value.forEach((data) => {
        data.map((item) => {
          // 根据时间差 看是否开始
          item.isStart = item.startTime - nowS > 0 ? false : true;
          const { d, h, m, s } = formatDate(
            item.isStart ? item.endTime : item.startTime
          );
          item.d = d;
          item.h = h;
          item.m = m;
          item.s = s;
        });
      });
    };
    // 时间处理
    const formatDate = (endTime) => {
      let stime = new Date().getTime() / 1000;
      let etime = new Date(endTime).getTime();
      let intervalTime = etime - stime;
      const d = Math.floor(intervalTime / 60 / 60 / 24);
      const h = Math.floor((intervalTime / 60 / 60) % 24);
      const m = Math.floor((intervalTime / 60) % 60);
      const s = Math.floor(intervalTime % 60);
      const res = {
        d: d < 10 ? "0" + d : d,
        h: h < 10 ? "0" + h : h,
        m: m < 10 ? "0" + m : m,
        s: s < 10 ? "0" + s : s,
      };
      return res;
    };
    const jump = async (item) => {
      let d = item;
      switch (d.taskType) {
        case 1: // 培训项目 -- 项目管理
          router.push({
            path: "/project/detail",
            query: {
              id: d.taskId,
            },
          });
          break;
        case 11: // 培训项目 -- 任务督导
          router.push({
            path: "/project/detail",
            query: {
              id: d.taskId,
            },
          });
          break;
        case 14: // 培训项目 -- 培训实施
          router.push({
            path: "/project/detail",
            query: {
              id: d.taskId,
            },
          });
          break;
        case 6: // 学习地图
          router.push({
            path: "/map/detail",
            query: {
              id: d.taskId,
              temp: d.mapTemplate,
            },
          });
          break;
        case 9: // 新人培训
          router.push({ path: "/newstaff/detail", query: { id: d.taskId } });
          break;
        case 5: // 课程专题
          router.push({ path: "/subject/detail", query: { id: d.taskId } });
          break;
        case 10: // 考试
          if (d.reexamId) {
            router.push({
              path: "/exam/detail",
              query: {
                id: d.taskId,
                did: d.detailId,
                reexamId: d.reexamId,
                taskType: 23,
              },
            });
          } else {
            router.push({
              path: "/exam/detail",
              query: {
                id: d.taskId,
                did: d.detailId,
              },
            });
          }
          break;
        case 2: // 每日一练
          router.push({
            path: "/exam/detail",
            query: {
              id: d.taskId,
              did: d.detailId,
            },
          });
          break;
        case 12: // 活动
          router.push({
            path: "/questionnaire/detail",
            query: {
              source: "activity",
              id: d.taskId,
              did: d.detailId,
              resourceId: d.resourceId,
              progress: d.progress,
              name: d.taskName,
              complete: d.complete,
            },
          });
          break;
        case 16: // 面授
          router.push({
            path: "/train/detail",
            query: {
              id: d.taskId,
              did: d.detailId,
            },
          });
          break;
        case 20: // 直播
          router.push({
            path: "/live/detail",
            query: {
              id: d.taskId,
              did: d.detailId,
            },
          });
          break;
      }
    };
    initData();
    // 资源是否可学
    const getResourceLearn = async (resource) => {
      const result = await projectDetail(resource.taskId);
      if (result.ret !== 0) return false;
      let data = result.data,
        nowTime = new Date().getTime();
      data.remainderDay = Math.floor(
        (data.endTime * 1000 - nowTime) / 86400000
      );
      data.remainderHours = Math.floor(
        (data.endTime * 1000 - nowTime) / 3600000
      );
      data.stages.forEach((s, sI) => {
        //项目未逾期
        if (data.complete != 3 && data.remainderDay >= 0) {
          // 阶段是否可学
          if (data.learnOrder == 2) {
            s.learnFlag = true;
          } else {
            if (sI == 0) {
              s.learnFlag = true;
            } else {
              data.stages[sI - 1].progress == 100 && (s.learnFlag = true);
            }
          }
          s.details.forEach((d, dI) => {
            //阶段明细是否可学
            if (s.learnFlag) {
              if (s.learnOrder == 2) {
                d.learnFlag = true;
              } else {
                if (dI == 0) {
                  d.learnFlag = true;
                } else {
                  s.details[dI - 1].progress == 100 && (d.learnFlag = true);
                }
              }
            }
            resource.detailId == d.detailId &&
              (resource.detailLearn = d.learnFlag);
          });
        } else {
          //阶段是否可学
          if (s.progress == 100) {
            s.learnFlag = true;
          } else {
            if (sI == 0) {
              s.learnFlag = true;
            }
          }
          s.details.forEach((d, dI) => {
            //阶段明细是否可学
            if (d.progress == 100) {
              d.learnFlag = true;
            } else {
              if (dI == 0) {
                d.learnFlag = true;
              } else {
                s.details[dI - 1].progress == 100 && (d.learnFlag = true);
              }
            }
            resource.detailId == d.detailId &&
              (resource.detailLearn = d.learnFlag);
          });
        }
        resource.stageId == s.stageId && (resource.stagesLearn = s.learnFlag);
      });
    };

    const currentChange = (type) => {
      if (type == 1) {
        if (currentIndex.value + 1 < todoList.value.length) {
          currentIndex.value++;
        }
      } else {
        if (currentIndex.value + 1 > 1) {
          currentIndex.value--;
        }
      }
    };
    return {
      todoList,
      dateFormat,
      getDetailType,
      currentIndex,
      currentChange,
      jump,
      taskType,
      now,
    };
  },
};
</script>

<style lang="less" scoped>
.todo {
  .content {
    .mixinWrap();
    .mod-tit {
      h3 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 0;
        line-height: 34px;
        .tips {
          padding-left: 4px;
          font-size: 14px;
          color: #666;
          font-weight: 400;
        }
      }
      .ctrl {
        .mixinFlex(flex-start; center);
        font-size: 14px;
        color: #666;
        .icon {
          font-size: 12px;
          margin: 0 6px;
          cursor: pointer;
          color: #333;
        }
      }
    }
    .card {
      .mixinFlex(flex-start; center);
      .item {
        width: 387px;
        border-radius: 8px;
        background: #f6faff;
        padding: 16px;
        margin-right: 20px;
        cursor: pointer;
        transition: all 0.3s ease;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          transform: translateY(-4px);
          box-shadow: 0 6px 20px rgba(148, 148, 148, 0.15);
          .bottom {
            .info {
              .title {
                color: @color-theme;
              }
            }
          }
        }
        .top {
          font-size: 16px;
          font-weight: 400;
          line-height: 26px;
          .mixinFlex(space-between; center);
          .type {
            color: #333;
          }
          .status {
            &.green {
              color: #44b44f;
            }
            &.orange {
              color: #e8993e;
            }
          }
        }
        .bottom {
          margin-top: 16px;
          .mixinFlex(space-between; center);
          .cover {
            .mixinImgWrap(60px; 60px);
          }
          .info {
            width: calc(100% - 76px);
            .title {
              color: #333;
              font-size: 16px;
              font-weight: 600;
              .mixinEllipsis(26px);
            }
            .time {
              color: #999;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 26px;
            }
          }
        }
      }
    }
  }
}
</style>
